import { Container, Col, Row } from "styled-bootstrap-grid";
import Image from "next/image";
import Link from "next/link";
import styled from "styled-components";
import theme from "@/styles/theme";
import { StyledImageWrapper } from "@/styles/style";

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 0.8rem 0;
`;

const StyledCoinHeader = styled.h3`
  margin: 0;
  color: ${theme.grey_2};
  font-weight: bold;

  span {
    color: ${theme.grey_2_5};
    font-weight: normal;
  }
`;

const StyledCoinInfo = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRightCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;
export default function CoinList({ coin, title }) {
  const imgUrl = `${process.env.CMC_STATIC_URL}static/img/coins/200x200/${coin.cmc_id}.png`;
  const sparklineUrl = `${process.env.CMC_SPARKLINE_URL}generated/sparklines/web/7d/2781/${coin.cmc_id}.svg`;
  return (
    <StyledLink href={`${coin.url_slug}`} title={title}>
      <Container fluid>
        <Row>
          <Col md={6}>
            <StyledCoinInfo>
              <StyledImageWrapper>
                <Image width={36} height={36} src={imgUrl} alt={coin.name} />
              </StyledImageWrapper>
              <StyledCoinHeader>
                {coin.name} <span>{coin.symbol}</span>
              </StyledCoinHeader>
            </StyledCoinInfo>
          </Col>
          <StyledRightCol md={6}>
            <Image width={192} height={48} src={sparklineUrl} alt={coin.name} />
          </StyledRightCol>
        </Row>
      </Container>
    </StyledLink>
  );
}
