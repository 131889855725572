import styled from "styled-components";
import Pagination from "rc-pagination";
import theme from "@/styles/theme";

const StyledPagination = styled(Pagination)`
  @media only screen and (max-width: 480px) {
    margin-left: 0;
  }
  display: flex;
  justify-content: center;
  user-select: none;
  padding: 0;

  &:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }

  > li {
    list-style: none;
  }

  .rc-pagination-total-text {
    float: left;
    height: 30px;
    line-height: 30px;
    list-style: none;
    padding: 0;
    margin: 0 8px 0 0;
  }

  .rc-pagination-item {
    cursor: pointer;
    border-radius: 6px;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    list-style: none;
    float: left;
    margin-right: 8px;

    a {
      text-decoration: none;
      box-shadow: none;
    }

    &:hover {
      border-color: ${theme.accent_3};

      a {
        color: ${theme.accent_3};
      }
    }
  }

  .rc-pagination-item-disabled {
    cursor: not-allowed;
    &:hover {
      border-color: ${theme.grey_4};

      a {
        color: ${theme.grey_4};
      }
    }
  }

  .rc-pagination-item-active {
    background-color: ${theme.accent_3};
    border-color: ${theme.accent_3};

    a {
      color: ${theme.white};
    }

    &:hover {
      a {
        color: ${theme.white};
      }
    }
  }

  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    &:after {
      content: "•••";
      display: block;
      letter-spacing: 2px;
      color: ${theme.grey_3};
      font-size: 12px;
      margin-top: 1px;
    }

    &:hover {
      &:after {
        color: ${theme.accent_3};
      }
    }
  }

  .rc-pagination-jump-prev {
    &:hover {
      &:after {
        content: "«";
      }
    }
  }

  .rc-pagination-jump-next {
    &:hover {
      &:after {
        content: "»";
      }
    }
  }

  .rc-pagination-jump-prev-custom-icon,
  .rc-pagination-jump-next-custom-icon {
    position: relative;
  }
  .rc-pagination-jump-prev-custom-icon:after,
  .rc-pagination-jump-next-custom-icon:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: all 0.2s;
    content: "•••";
    opacity: 1;
    display: block;
    letter-spacing: 2px;
    color: ${theme.grey_3};
    font-size: 12px;
    margin-top: 1px;
  }
  .rc-pagination-jump-prev-custom-icon .custom-icon-jump-prev,
  .rc-pagination-jump-next-custom-icon .custom-icon-jump-prev,
  .rc-pagination-jump-prev-custom-icon .custom-icon-jump-next,
  .rc-pagination-jump-next-custom-icon .custom-icon-jump-next {
    opacity: 0;
    transition: all 0.2s;
  }
  .rc-pagination-jump-prev-custom-icon:hover:after,
  .rc-pagination-jump-next-custom-icon:hover:after {
    opacity: 0;
    color: ${theme.grey_3};
  }
  .rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
  .rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
  .rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
  .rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
    opacity: 1;
    color: ${theme.accent_3};
  }
  .rc-pagination-prev,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    margin-right: 8px;
  }
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    cursor: pointer;
    color: ${theme.grey_2};
    font-size: 10px;
    border-radius: 6px;
    list-style: none;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    float: left;
    text-align: center;
  }
  .rc-pagination-prev a:after {
    content: "‹";
    display: block;
  }
  .rc-pagination-next a:after {
    content: "›";
    display: block;
  }
  .rc-pagination-prev,
  .rc-pagination-next {
    border: 1px solid ${theme.grey_4};
    font-size: 18px;
  }
  .rc-pagination-prev a,
  .rc-pagination-next a {
    color: ${theme.grey_2};
  }
  .rc-pagination-prev a:after,
  .rc-pagination-next a:after {
    margin-top: -1px;
  }
  .rc-pagination-disabled {
    cursor: not-allowed;
  }
  .rc-pagination-disabled a {
    color: ${theme.grey_3};
  }
  .rc-pagination-disabled .rc-pagination-item,
  .rc-pagination-disabled .rc-pagination-prev,
  .rc-pagination-disabled .rc-pagination-next {
    cursor: not-allowed;
  }
  .rc-pagination-disabled .rc-pagination-item:hover,
  .rc-pagination-disabled .rc-pagination-prev:hover,
  .rc-pagination-disabled .rc-pagination-next:hover {
    border-color: ${theme.grey_4};
  }
  .rc-pagination-disabled .rc-pagination-item:hover a,
  .rc-pagination-disabled .rc-pagination-prev:hover a,
  .rc-pagination-disabled .rc-pagination-next:hover a {
    color: ${theme.grey_4};
  }
  .rc-pagination-disabled .rc-pagination-jump-prev,
  .rc-pagination-disabled .rc-pagination-jump-next {
    pointer-events: none;
  }
  .rc-pagination-options {
    float: left;
    margin-left: 15px;
  }
  .rc-pagination-options-size-changer {
    float: left;
    width: 80px;
  }
  .rc-pagination-options-quick-jumper {
    float: left;
    margin-left: 16px;
    height: 28px;
    line-height: 28px;
  }
  .rc-pagination-options-quick-jumper input {
    margin: 0 8px;
    box-sizing: border-box;
    background-color: ${theme.white};
    border-radius: 6px;
    border: 1px solid ${theme.grey_4};
    outline: none;
    padding: 3px 12px;
    width: 50px;
    height: 28px;
  }
  .rc-pagination-options-quick-jumper input:hover {
    border-color: ${theme.accent_3};
  }
  .rc-pagination-options-quick-jumper button {
    display: inline-block;
    margin: 0 8px;
    font-weight: 500;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 12px;
    border-radius: 6px;
    height: 28px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: rgba(0, 0, 0, 0.65);
    background-color: ${theme.white};
    border-color: ${theme.grey_4};
  }
  .rc-pagination-options-quick-jumper button:hover,
  .rc-pagination-options-quick-jumper button:active,
  .rc-pagination-options-quick-jumper button:focus {
    color: ${theme.accent_3};
    background-color: ${theme.white};
    border-color: ${theme.accent_3};
  }
  .rc-pagination-simple .rc-pagination-prev,
  .rc-pagination-simple .rc-pagination-next {
    border: none;
    height: 24px;
    line-height: 24px;
    margin: 0;
    font-size: 18px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager {
    float: left;
    margin-right: 8px;
    list-style: none;
  }
  .rc-pagination-simple .rc-pagination-simple-pager .rc-pagination-slash {
    margin: 0 10px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager input {
    margin: 0 8px;
    box-sizing: border-box;
    background-color: ${theme.white};
    border-radius: 6px;
    border: 1px solid ${theme.grey_4};
    outline: none;
    padding: 5px 8px;
    min-height: 20px;
  }
  .rc-pagination-simple .rc-pagination-simple-pager input:hover {
    border-color: ${theme.accent_3};
  }
  .rc-pagination-simple .rc-pagination-simple-pager button {
    display: inline-block;
    margin: 0 8px;
    font-weight: 500;
    text-align: center;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0 8px;
    font-size: 12px;
    border-radius: 6px;
    height: 26px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    color: rgba(0, 0, 0, 0.65);
    background-color: ${theme.white};
    border-color: ${theme.grey_4};
  }
  .rc-pagination-simple .rc-pagination-simple-pager button:hover,
  .rc-pagination-simple .rc-pagination-simple-pager button:active,
  .rc-pagination-simple .rc-pagination-simple-pager button:focus {
    color: ${theme.accent_3};
    background-color: ${theme.white};
    border-color: ${theme.accent_3};
  }
  @media only screen and (max-width: 1024px) {
    .rc-pagination-item-after-jump-prev,
    .rc-pagination-item-before-jump-next {
      display: none;
    }
  }
`;

export default StyledPagination;
